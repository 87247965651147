import classNames from 'classnames';
import Image from 'next/image';

import Icon, { IconsNames } from '@/components/v3/Icon';
import { ResponsiveImagesWithAttributes } from '@/types/cms';

import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

type CheckImagesProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  elementRef?: React.RefObject<HTMLDivElement>;
  title: string;
  description: string;
  background?: string;
  iconDescriptions: {
    iconName: IconsNames;
    description: string;
  }[];
  logoImages: ResponsiveImagesWithAttributes[];
};
export function CheckImages({
  referId,
  forwardRef,
  elementRef,
  title,
  description,
  background,
  iconDescriptions,
  logoImages,
}: CheckImagesProps) {
  return (
    <div ref={elementRef}>
      <section
        id={referId}
        ref={forwardRef}
        className={classNames(
          'flex flex-col md:flex-row gap-40 justify-center items-center md:p-40 px-24 pt-40 lg:pt-80 pb-80 lg:pb-80 w-full',
          background,
        )}
      >
        <div className="flex flex-col md:justify-center rounded-xl md:rounded-md">
          <h3
            className="font-display font-bold"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="hidden lg:flex mt-8 lg:mb-8 md:text-lg"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="flex flex-col gap-16 mt-8">
            {iconDescriptions.map(item => (
              <div className="flex gap-8 items-center" key={item.description}>
                <Icon
                  name={item.iconName}
                  className="min-w-[24px] min-h-[24px] text-stone-500 fill-current"
                />
                <p className="text-lg">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-24 min-w-[288px]">
          {logoImages.map(item => (
            <Image
              key={item.image.id}
              src={item.image.path}
              alt={item.image.alternativeText}
              width={item.width}
              height={item.height}
              className={item.className}
              objectFit="contain"
            />
          ))}
        </div>
      </section>
    </div>
  );
}
