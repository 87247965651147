import { isS3 } from './amazon';

export default function createSrcset(imgUrl: string): string {
  if (isS3(imgUrl)) {
    return imgUrl;
  }

  return `https://res.cloudinary.com/dunz5zfpt/image/upload/f_auto,q_auto,w_420/${imgUrl} 320w,
  https://res.cloudinary.com/dunz5zfpt/image/upload/f_auto,q_auto,w_600/${imgUrl} 420w,
  https://res.cloudinary.com/dunz5zfpt/image/upload/f_auto,q_auto,w_800/${imgUrl} 600w,
  https://res.cloudinary.com/dunz5zfpt/image/upload/f_auto,q_auto,w_1000/${imgUrl} 800w,
  https://res.cloudinary.com/dunz5zfpt/image/upload/f_auto,q_auto,w_1200/${imgUrl} 1000w`;
}
