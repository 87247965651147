import Image from 'next/image';
import QRCodeImage from 'react-qr-code';

import { ImageResponsiveCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';
import { Mark, MarkProps } from './Mark';

interface Props {
  referId: string;
  title: string;
  description: string;
  backgroundImage?: ImageResponsiveCMSProps;
  qrCodeLink?: string;
  qrCodeTitle?: string;
  qrCodeDescription?: string;
  marks: MarkProps[];
  buttons?: ButtonProps[];
}

export function QRCodeMarksBanner({
  referId,
  title,
  description,
  backgroundImage,
  qrCodeLink,
  qrCodeTitle,
  qrCodeDescription,
  marks,
  buttons,
}: Props) {
  return (
    <section
      id={referId}
      className="flex overflow-hidden relative justify-center items-center py-40 lg:py-80 px-24 w-full bg-stone-700"
    >
      {backgroundImage && (
        <>
          <Image
            className="hidden xl:flex absolute top-0 w-full"
            layout="fill"
            src={backgroundImage.web.path}
            alt={backgroundImage.web.alternativeText}
            quality={backgroundImage.webQuality || 70}
            objectFit="cover"
            objectPosition="top"
          />

          <Image
            className="hidden md:flex xl:hidden absolute top-0 w-full"
            layout="fill"
            src={backgroundImage.tablet.path}
            alt={backgroundImage.tablet.alternativeText}
            quality={backgroundImage.tabletQuality || 70}
            objectFit="cover"
            objectPosition="top"
          />

          <Image
            className="flex md:hidden absolute top-0 w-full"
            layout="fill"
            src={backgroundImage.mobile.path}
            alt={backgroundImage.mobile.alternativeText}
            quality={backgroundImage.mobileQuality || 70}
            objectFit="cover"
            objectPosition="top"
          />
        </>
      )}

      <div className="flex relative gap-40 items-center p-24 xl:p-80 lg:py-80 max-w-[720px] md:max-w-none xl:max-w-7xl bg-white rounded-2xl xl:rounded-6xl">
        <div className="flex md:grid lg:flex flex-col lg:flex-row md:grid-cols-2 md:gap-24 items-center">
          <div className="max-w-[540px]">
            <h2
              dangerouslySetInnerHTML={{ __html: title }}
              className="pb-8 font-display text-2xl lg:text-6xl font-bold leading-6 lg:leading-9"
            />

            <p
              className="pb-24 font-normal text-base lg:text-xl"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            {buttons && (
              <div className="hidden md:flex">
                {buttons.map(btn => (
                  <Button key={btn.label} {...btn} />
                ))}
              </div>
            )}
          </div>

          <div className="flex md:grid flex-wrap md:grid-cols-3 lg:grid-cols-4 gap-16 justify-between justify-items-center items-center py-8 max-w-[318px] md:max-w-none xl:max-w-[530px] max-h-[128px] lg:max-h-[208px]">
            {marks.map(mark => (
              <Mark {...mark} key={mark.logo.id} />
            ))}
          </div>
        </div>

        {qrCodeLink && qrCodeTitle && qrCodeDescription && (
          <div className="hidden xl:flex flex-col items-center py-40 px-24 w-full max-w-[495px] bg-display-100 rounded-md h-min">
            <p
              className="pb-10 font-display text-2xl font-bold text-center whitespace-nowrap"
              dangerouslySetInnerHTML={{ __html: qrCodeTitle }}
            />

            <p
              className="pb-24 font-normal text-lg text-center whitespace-nowrap"
              dangerouslySetInnerHTML={{ __html: qrCodeDescription }}
            />

            <div className="p-24 bg-white rounded-[8px]">
              <QRCodeImage size={196} value={qrCodeLink} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
