import Image from 'next/image';

import { ImageResponsiveCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';
import classNames from 'classnames';

interface CardBannerProps {
  referId?: string;
  images: ImageResponsiveCMSProps;
  title?: string;
  tag?: string;
  description?: string;
  buttons: ButtonProps[];
  unlimitedImageSize?: boolean;
  reverse?: boolean;
  balancedSpacing?: boolean;
}

export function CardBanner({
  referId,
  images,
  title,
  description,
  buttons,
  tag,
  unlimitedImageSize = false,
  reverse = false,
  balancedSpacing = false,
}: CardBannerProps) {
  return (
    <div
      className="flex justify-center py-40 lg:py-80 px-16 md:px-24 w-full"
      id={referId}
    >
      <div
        className={classNames(
          'flex lg:grid flex-col md:flex-row lg:grid-cols-2 md:gap-24 lg:gap-40 justify-center items-center md:p-24 lg:p-40 px-12 pt-12 pb-16 w-full max-w-[455px] md:max-w-[1060px] md:min-h-[294px] lg:min-h-[318px] bg-display-100 rounded-2xl md:rounded-6xl lg:rounded-6xl',
          {
            'flex-row-reverse text-left': reverse,
          },
        )}
        style={{
          direction: reverse ? 'rtl' : 'ltr',
        }}
      >
        <div className="flex overflow-hidden relative order-1 md:order-2 w-full max-w-[470px] md:max-w-[485px] min-h-[197px] rounded-md md:rounded-2xl md:rounded-bl-none">
          <div className="block md:hidden h-[212px] lg:h-[248px]">
            <Image
              src={images.mobile.path}
              alt={images.mobile.alternativeText}
              layout="fill"
              quality={images.mobileQuality || 70}
              className="object-cover"
            />
          </div>

          <div
            className={classNames('hidden lg:block', {
              'h-[212px] lg:h-[248px]': unlimitedImageSize,
            })}
          >
            <Image
              src={images.web.path}
              alt={images.web.alternativeText}
              {...(unlimitedImageSize
                ? {
                    width: images.web.width ? images.web.width / 2 : 0,
                    height: images.web.height ? images.web.height / 2 : 0,
                  }
                : {
                    layout: 'fill',
                  })}
              quality={images.webQuality || 70}
              className="object-cover"
            />
          </div>

          <div className="hidden md:block lg:hidden w-[357px] h-[248px]">
            <Image
              src={images.tablet.path || images.web.path}
              alt={images.tablet.alternativeText || images.web.alternativeText}
              layout="fill"
              quality={images.tabletQuality || images.webQuality || 70}
              className="object-cover"
            />
          </div>
        </div>
        <div className="flex flex-col order-2 md:order-1 px-4 md:px-0 mt-24 md:mt-0 w-full md:min-w-[315px] md:max-w-[455px] lg:max-w-none">
          {tag && (
            <p
              className="py-4 px-8 mb-8 w-max font-semibold leading-4 text-stone-800 bg-stone-200 rounded-md paragraph-16"
              dangerouslySetInnerHTML={{ __html: tag }}
            />
          )}

          {title && (
            <h2
              className="font-display text-3xl lg:text-4xl font-bold leading-7 lg:leading-8"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}

          {description && (
            <p
              className={classNames(
                'md:text-base leading-4 lg:leading-5 lg:paragraph-18',
                {
                  'mt-8 mb-24': !balancedSpacing,
                  'my-16': balancedSpacing,
                },
              )}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}

          {!!buttons?.length && (
            <div
              className={classNames(
                'flex gap-16 flex-wrap w-full lg:flex-nowrap',
                {
                  'flex-row-reverse': !!reverse,
                },
              )}
            >
              {buttons?.map(buttonProps => (
                <Button
                  key={buttonProps.label}
                  className="w-full md:w-full lg:w-max"
                  {...buttonProps}
                  sectionReference={title as string}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
