import { twMerge } from 'tailwind-merge';

import Icon from '@/components/v3/Icon';
import { FeatureCardStonjiProps } from '@/types/cms';

import StonjiIcon from '../../base/ui/StonjiIcon';
import styles from './MutedRTBs.module.css';

interface MutedRTBsProps {
  referId: string;
  title: string;
  backgroundColor: string;
  showGreenStripe: boolean;
  cards: Omit<FeatureCardStonjiProps, 'tag'>[];
}

export function MutedRTBs({
  referId,
  title,
  cards,
  showGreenStripe = false,
  backgroundColor = 'bg-display-0',
}: MutedRTBsProps) {
  return (
    <div
      id={referId}
      className={twMerge(styles.outerContainer, `md:${backgroundColor}`)}
    >
      {showGreenStripe && (
        <div className="md:block absolute top-[50%] right-0 left-0 z-0 w-full h-[50%] bg-stone-500" />
      )}
      <div className={styles.innerContainer}>
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          className="w-full max-w-full lg:max-w-[250px] font-display text-center lg:text-left break-words heading-3"
        />
        <div className="flex flex-col justify-center items-center p-24 lg:p-40">
          <Icon
            name="chevron-down"
            className=" w-40 min-w-[40px] h-40 text-stone-500 lg:-rotate-90 fill-current"
          />
        </div>
        <div className="flex flex-col md:flex-row gap-40">
          {cards.map(({ title, description, stonjiName }, index) => (
            <div
              key={index}
              className="flex flex-row md:flex-col flex-1 items-start"
            >
              <StonjiIcon
                name={stonjiName}
                className="w-[64px] md:w-80 min-w-[64px] md:min-w-[80px] h-[64px] md:h-80"
              />
              <div className="flex flex-col pl-16 md:pl-0">
                {title && (
                  <p
                    className="md:pt-16 font-bold paragraph-16 md:paragraph-18"
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                )}
                {description && (
                  <p
                    className="pt-4 font-normal paragraph-16 md:paragraph-18"
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
